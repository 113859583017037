import { Text } from 'components/dataDisplay'
import React, { FC, useRef, useState, useEffect } from 'react'
import cx from 'classnames'
import Artist from '../Artist/Artist'
import s from './Artists.module.scss'
import { WidthContainer } from 'components/layout'
import { UseThrottlingOnScreen } from 'hooks'
import { useDraggable } from 'react-use-draggable-scroll'
import { Animated } from 'components/feedback'
import { useDevice } from 'device'


const artists = [
  { name: 'tjo', src: '@0xTjo', username: 'tjo' },
  { name: 'Rik Oostenbroek', src: '@RikOostenbroek', username: 'rikoostenbroek' },
  { name: 'terrell jones', src: '@terrelldom', username: 'terrelldom' },
  { name: 'Other World', src: '@otherworld_xx' },
  { name: 'ANT', src: '@Antpantone', username: 'antpantone' },
  { name: 'Mariah', src: '@0xVestica', username: '0xvestica' },
  { name: 'DotPigeon', src: '@_dotpigeon', username: 'dotpigeon' },
  { name: 'P1A', src: '@P1AbyPIA', username: 'p1a' },
  { name: 'A-Mashiro', src: '@A_Mashiro_nft', username: 'amashiro' },
  { name: 'RΞY', src: '@reylarsdam' },
  { name: 'amu', src: '@amusvision', username: 'amusvision' },
  { name: 'bruno urli', src: '@brunourIi', username: 'bruno' },
  { name: 'k.a', src: '@providerka', username: 'providerka' },
  { name: 'Maria Fynsk Norup', src: '@mariafynsknorup', username: 'mariafynsknorup' },
  { name: 'Space Case', src: '@adhd143', username: 'spacecase' },
  { name: 'crow', src: '@crowgmi', username: 'crow' },
  { name: 'soulo', src: '@soulosaint' },
  { name: 'ARCHAN NAIR', src: '@ArchanNair' },
  { name: 'Dangiuz', src: '@dangiuz', username: 'dangiuz' },
  { name: 'Etienne', src: '@etienecrauss' },
  { name: 'Gelo', src: '@gelo_arch', username: 'gelo' },
  { name: 'RedruM', src: '@RedruMxNFT', username: 'redrum' },
  { name: 'Jesperish', src: '@Jesperish' },
  { name: 'Anton Reva', src: '@savemymindAL', username: 'savemymind' },
]

const Artists: FC<{className?: string}> = ({ className }) => {
  const { isMobile } = useDevice()
  const ref = useRef()
  const { events } = useDraggable(ref)
  const refWho = useRef()
  const isActive = UseThrottlingOnScreen(refWho)
  const [ randomized, setRandomized ] = useState(artists)

  useEffect(() => {
    setRandomized(artists.sort(() => Math.random() - 0.5))
  }, [])

  return (
    <WidthContainer className={isMobile ? 'overflow-hidden mt-40' : 'mt-80 mb-120'}>
      <div className={s.container}>
        <div className={cx('flex flex-col', className)}>
          <div className="relative">
            <Text style="h2" tag="h2">
              WHO WE ARE
            </Text>
            <img className={cx(s.who, isActive && s.who_active)} src="/images/graphics/who.png" alt="who we are" ref={refWho} />
          </div>
          <Text
            className={s.desc}
            style={isMobile ? 'p1' : 'p2'}
            tag="p"
            color="gray-50"
            message="We are a consortium of over 30 artists and collectors committed to growing the art market of the Web 3 age via its most democratic medium - NFT editions."
          />
        </div>
        <Animated type="fadeInRight">
          <div className={s.artists} {...events} ref={ref}>
            {randomized.map(props => <Artist key={props.src} {...props} />)}
          </div>
        </Animated>
      </div>
    </WidthContainer>
  )
}

export default Artists
