import React, { FC } from 'react'
import { Text } from 'components/dataDisplay'
import s from './Artist.module.scss'
import { useDevice } from 'device'


type ArtistProps = {
  name: string
  src: string
  username?: string
}

const ArtistImage: FC<ArtistProps> = ({ name, src, username }) => {
  if (!Boolean(username)) {
    return <img className={s.img} src={`/images/artists/${src}.jpg`} alt={name} />
  }

  return (
    <a title={name} href={`/user/${username}`} target="_blank" rel="noreferrer">
      <img className={s.img} src={`/images/artists/${src}.jpg`} alt={name} />
    </a>
  )
}

const Artist: FC<ArtistProps> = ({ name, src, username }) => {
  const { isMobile } = useDevice()

  const iconSize = isMobile ? 14 : 25

  return (
    <div className={s.container}>
      <ArtistImage src={src} name={name} username={username} />
      <div className={s.bar}>
        <Text style={isMobile ? 'p2' : 'u1'} message={name} color="white-100" tag="p" />
        <a
          className="mt-8 flex items-center hover:underline"
          href={`https://twitter.com/${src}`}
          target="_blank"
          rel="noreferrer"
          title={name}
        >
          <img
            src={'/images/twitter.svg'}
            alt={'twitter'}
            width={iconSize}
            height={iconSize}
            loading="lazy"
          />
          <Text
            className="ml-8"
            style={isMobile ? 'c3' : 'p2'}
            message={src}
            color="white-100"
            tag="p"
          />
        </a>
      </div>
    </div>
  )
}

export default Artist
